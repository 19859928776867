import "./src/styles/theme/fonts.scss"

import "./src/styles/global.scss"

export const onClientEntry = () => {
  if (typeof window !== "undefined") {
    if (window.location.pathname.startsWith("/events")) {
      const $ = require("jquery")
      require("bootstrap/dist/js/bootstrap.bundle.min.js")

      window.$ = $
      window.jQuery = $
      window.Popper = require("@popperjs/core")
    }
  }
}
